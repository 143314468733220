<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Transactions List
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Transactions</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
            Filter
            </h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterTransactions" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="classification" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="classification" horizontal label="Classification">
                        <b-form-select id="classification" v-model="classification" @change="switchSubClass" :options="classifications"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="sub_classification" rules="required">
                      <b-form-group slot-scope="{ errors }" label-for="sub_classification" horizontal label="Sub Classification">
                        <b-form-select id="sub_classification" :disabled="!subClassifications.length" v-model="dataSubClassification" :options="subClassifications"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="city_id" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal label="City">
                        <b-form-select id="city_id" v-model="city_id" :options="cities"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="year_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="year_range" horizontal label="Year Range">
                        <date-picker v-model="yearRange" style="width:100%;" @change="filterTransactions" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="plan_no" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="plan_no" horizontal label="Plan No">
                        <b-form-input type="text" id="plan_no" v-model="plan_no" placeholder="Plan No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="plot_no" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="plot_no" horizontal label="Plot No">
                        <b-form-input type="text" id="plot_no" v-model="plot_no" placeholder="Plot No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="district">
                      <b-form-group slot-scope="{ errors }" label-for="district" horizontal label="District">
                        <b-form-input type="text" id="district" v-model="district" placeholder="District"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="min_amount" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="min_amount" horizontal label="Min Amount">
                        <b-form-input type="number" id="min_amount" v-model="min_amount" placeholder="Min Amount"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="max_amount" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="max_amount" horizontal label="Max Amount">
                        <b-form-input type="number" id="max_amount" v-model="max_amount" placeholder="Max Amount"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="from_meter_square" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="from_meter_square" horizontal label="From Meter Square">
                        <b-form-input type="number" id="from_meter_square" v-model="from_meter_sq" placeholder="From Meter Square"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="to_meter_square" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="to_meter_square" horizontal label="To Meter Square">
                        <b-form-input type="number" id="to_meter_square" v-model="to_meter_sq" placeholder="To Meter Square"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="hij_start_date">
                      <b-form-group slot-scope="{ errors }" label-for="hij_start_date" horizontal label="Hij Start Date">
                        <b-form-input type="text" id="hij_start_date" v-model="hij_start_date" placeholder="1430/09/08"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="hij_end_date">
                      <b-form-group slot-scope="{ errors }" label-for="hij_end_date" horizontal label="Hij Start Date">
                        <b-form-input type="text" id="hij_end_date" v-model="hij_end_date" placeholder="1430/09/08"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="invoice_no" rules="numeric">
                      <b-form-group slot-scope="{ errors }" label-for="invoice_no" horizontal label="Invoice Number">
                        <b-form-input type="number" id="invoice_no" v-model="invoice_no" placeholder="Invoice Number"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
      <form action="#">
        <div class="form-group d-lg-flex">
          <input type="number" class="form-control" @change="paginateChange" placeholder="perPage" v-model="paginate">
        </div>
      </form>
      </div>
      <div class="col-6">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="text" class="form-control" placeholder="Search Here" v-model="searchInput">
            <button type="button" class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0" :disabled="searchInput == ''">Search</button>
          </div>
        </form>
      </div>
      <div class="col-3">
        <form action="#">
          <div class="form-group d-lg-flex">
            <b-dropdown variant="primary" right split text="Export">
              <b-dropdown-item @click="exportData('csv')">CSV</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="exportData('xls')">Excel</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="exportData('json')">JSON</b-dropdown-item>
            </b-dropdown>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
<!--            <h4 class="card-title">{{ list_title }}</h4>-->
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">{{ list_title }}</h4>
              <download-excel
                class="btn btn-success"
                :data="transactions"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="transaction.xlxs">
                Download Transactions
              </download-excel>
            </div>
            <b-table ref="table" :items="transactions" :busy="isBusy" id="table-list" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:cell(en_date)="data">
                <span>{{ (data.item.en_date == null) ? "N/A" : data.item.en_date }}</span>
              </template> <!--
              <template v-slot:cell(blocks)="data">
                <span>{{ showBlocks(data.item.blocks) }}</span>
              </template> -->
              <template v-slot:cell(action)="data">
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'transaction-view', params: {id: data.item.id} }">View</router-link>
                </b-badge>
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'transaction-edit', params: {id: data.item.id} }">Edit</router-link>
                </b-badge>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              last-number
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import { ValidationObserver } from 'vee-validate'
import _ from "lodash"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import exportFromJSON from 'export-from-json'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      list_title: "Transactions",
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      isBusy: true,
      classification: "",
      city_id: "",
      yearRange: "",
      min_amount: "",
      max_amount: "",
      from_meter_sq: "",
      to_meter_sq: "",
      hij_start_date: "",
      hij_end_date: "",
      plan_no: "",
      plot_no: "",
      district: "",
      invoice_no: "",
      dataSubClassification: "",
      classifications: [
        {value: "", text: "Select classification"},
        {value: "Commercial", text: "Commercial"},
        {value: "Residential", text: "Residential"}
      ],
      subClassifications: [],
      fields: [
        { key: 'id', sortable: true },
        { key: 'plot_id', sortable: true },
        { key: 'plot_no', sortable: true},
        { key: 'plan', sortable: true },
        { key: 'invoice_no', sortable: true },
        { key: 'amount', sortable: true },
        { key: 'price_per_sq_meter', sortable: true },
        { key: 'meter_sq', sortable: true },
        { key: 'classification', sortable: true},
        { key: 'sub_classification', sortable: true},
        { key: 'city', sortable: true},
        { key: 'district', sortable: true},
        { key: 'en_date', sortable: true },
        { key: 'hij_date', sortable: true },
        { key: 'action', sortable: false}
      ],
      json_fields: {
        'ID': 'id',
        'Created': 'created_at',
        "Plan Number": "plan",
        'Plot No': 'plot_no',
        'Plot ID': 'plot_id',
        'Invoice No': 'invoice_no',
        'amount': 'amount',
        'Price Per Sq Meter': 'price_per_sq_meter',
        'Meter Sq': 'meter_sq',
        'Classification': 'classification',
        'Sub Classification': 'sub_classification',
        'City': 'city',
        'District': 'district',
        'En Date': 'en_date',
        'Hij Date': 'hij_date',
      }
    }
  },
  computed: {
    rows() {
      return this.$store.state.transaction.meta.total
    },
    paginationPageSize() {
      return this.paginate
    },
    transactions() {
      return this.$store.state.transaction.transactions
    },
    cities() {
      let cities = this.$store.getters['city/listCities']
      cities.unshift({value: "", text: "Select City"})
      return cities
    },
    listSubClassifications() {
      return this.$store.getters['plot/listSubClassification']
    },
    commercialClassifications() {
      return this.$store.getters['plot/listCommercialClassification']
    },
    residentialClassifications() {
      return this.$store.getters['plot/listResidentialClassification']
    }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.transaction.meta.current_page)
        this.fetchTransactions(this.currentPage)
    },
  },
  methods: {
    showBlocks(blocks) {
      return blocks.join(", ")
    },
    fetchTransactions(current_page = 1) {
      this.isBusy = true

      const payload = {
        meta: {
          page: current_page,
          paginate: this.paginationPageSize,
          classification: this.classification,
          sub_classification_id: this.dataSubClassification,
          city_id: this.city_id,
          plan_no: this.plan_no,
          plot_no: this.plot_no,
          invoice_no: this.invoice_no,
          district: this.district,
          amount_start: this.min_amount,
          amount_end: this.max_amount,
          to_meter_sq: this.to_meter_sq,
          from_meter_sq: this.from_meter_sq,
          hij_start_date: this.hij_start_date,
          hij_end_date: this.hij_end_date,
          start_date: this.yearRange.length ? this.yearRange[0] : "",
          end_date: this.yearRange.length ? this.yearRange[1]: ""
        },
      }

      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("transaction/fetchTransactions", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
    fetchCities() {
      this.isBusy = true

      const payload = {
        meta: {
          page: 1,
          paginate: 200
        },
      }
      this.$store.dispatch("city/fetchCities", payload)
    },
    filterTransactions: _.debounce(function() {
      this.fetchTransactions(this.currentPage)
    }, 2000),
    paginateChange: _.debounce(function() {
      if (this.paginate > 0 && this.paginate != "")
        this.fetchTransactions(this.currentPage)
    }, 2000),
    fetchSubClassifications() {
      const payload = {
        meta: {
          page: this.currentPage,
          perPage: this.paginationPageSize
        },
      }
      this.$store.dispatch("plot/fetchSubClassifications", payload)
    },
    switchSubClass() {
      if(this.classification === "")
        this.subClassifications = []
      else if(this.classification === "Commercial") {
        this.subClassifications = this.commercialClassifications.map(com => {
          return {
            value: com.id,
            text: com.name
          }
        })
      }
      else if(this.classification === "Residential") {
        this.subClassifications = this.residentialClassifications.map(res => {
          return {
            value: res.id,
            text: res.name
          }
        })
      }

    },
    exportData(exportType) {
      const data = JSON.parse(JSON.stringify(this.transactions))
      const fileName = "transactions"
      exportFromJSON({ data, fileName, exportType })
    }
  },
  created() {
    this.fetchTransactions()
    this.fetchCities()
    this.fetchSubClassifications()
 },
 components: {
   ValidationObserver,
   DatePicker
 }
}
</script>
